import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './App.css';
import React, {Suspense, useEffect} from 'react';
import { useRoutes } from 'react-router-dom';
import { FloatButton } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons"
import { ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalStyles from './components/GlobalStyles';
import Loading from './components/Loading';

import theme from './theme';
import routes from './routes';

const App = (props) => {
    const routing = useRoutes(routes);
    const queryClient = new QueryClient(); // {defaultOptions: {queries: {retry: 0, suspense: true, cacheTime: 3600000, refetchOnWindowFocus: false}}}

    const handleClickHelpButton = () => {
        window.open("https://trialinformatics.atlassian.net/servicedesk/customer/portal/3");
    };

    useEffect(() => {
        if (window) {
            const hostname = window.location.hostname;
            if (hostname.includes('ti-otb')) {
                window.location.href = `https://${hostname.replace('ti-otb', 'titdh')}${window.location.pathname}`
            }
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<Loading />}>
                    {routing}
                    <FloatButton
                        icon={<CustomerServiceOutlined />}
                        onClick={handleClickHelpButton}
                    />
                </Suspense>
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
