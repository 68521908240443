import {colors, createTheme} from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: 'rgb(123,106,248)',
    },
    background: {
      paper: '#202020',
    },
  },
  shadows,
  typography,
  overrides: {
    MTableToolbar: {
      root: {
        paddingLeft: '0px',
      },
      gutter: {
        paddingLeft: '0px',
      }
    },
    MuiToolbar: {
      padding: '0px',
    },
    MuiTableCell: {
      root: {
        fontSize: '14px'
      }
    }
  },
  
});

export default theme;
